import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Hero from "src/components/Hero"
import Container from "src/components/Container"
import { ArticleContent, FullTextColumn } from "src/templates/article"
import { pageNormalizer } from "src/utils/contentNormalizers"

export const query = graphql`
  query fullWidthPageByIdQuery($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFragment
    }
  }
`

export default props => {
  const { wordpressPage } = props.data

  const { title, content, path, featured_media } = pageNormalizer(wordpressPage)

  const parsedContent = parseContent(content)

  return (
    <>
      <SEO title={title} image={featured_media} />
      <Ad type="page/before_header" />
      <PageWrapper>
        <Header />
        <Hero title={title} image={featured_media} />
        <Container>
          <Content>
            <FullTextColumn>{parsedContent}</FullTextColumn>
          </Content>
        </Container>
      </PageWrapper>
      <Ad type="page/before_footer" />
    </>
  )
}

const Content = styled(ArticleContent)`
  margin-bottom: 110px;
`
