import React, { useEffect, useState } from "react"
import Article from "src/templates/article"
import wpHttpClient from "src/utils/wpHttpClient"
import locationQueryParams from "src/utils/locationQueryParams"
import Page from "src/templates/page"
import PageFullWidth from "src/templates/pageFullWidth"
import PageSubscribe from "src/templates/pageSubscribe"

export default () => {
  const [post, setPost] = useState({
    title: "",
    content: "",
    type: "post",
    acf: {},
  })

  const setMessage = message => {
    setPost({
      title: message,
      content: "",
      type: "post",
      acf: {},
    })
  }

  useEffect(() => {
    const getPost = async id => {
      try {
        const results = await wpHttpClient.get(`air/v1/site/preview/${id}`)
        setPost(results.data)
      } catch (e) {
        setMessage("Preview not found")
      }
    }
    setMessage("Preview is loading...")
    const query = locationQueryParams.parse()
    if (query.id) {
      getPost(query.id)
    } else {
      setMessage("Preview ID is missing")
    }
  }, [])

  return !post ? (
    <>'Loading...'</>
  ) : (
    <>
      {post.type === "post" && <Article data={{ wordpressPost: post }} />}
      {post.type === "page" && (
        <>
          {post.template === "full-width" && (
            <PageFullWidth data={{ wordpressPage: post }} />
          )}
          {post.template === "subscribe" && (
            <PageSubscribe data={{ wordpressPage: post }} />
          )}
          {!post.template ||
            (post.template === "default" && (
              <Page data={{ wordpressPage: post }} />
            ))}
        </>
      )}
    </>
  )
}
