import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Hero from "src/components/Hero"
import Container from "src/components/Container"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import { PrimaryTitle } from "src/components/Typography"
import {
  ArticleContent,
  TextColumn,
  SharingColumn,
  SideColumn,
} from "src/templates/article"
import { pageNormalizer } from "src/utils/contentNormalizers"

export const query = graphql`
  query subscribePageByIdQuery($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFragment
      acf {
        subscribe_image {
          source_url
        }
        subscribe_text
      }
    }
  }
`

export default props => {
  const { wordpressPage } = props.data

  const {
    title,
    content,
    path,
    featured_media,
    acf: { subscribe_text, subscribe_image },
  } = pageNormalizer(wordpressPage)

  const parsedContent = parseContent(content)

  return (
    <>
      <SEO title={title} image={featured_media} />
      <Ad type="page/before_header" />
      <Wrapper>
        <Header />
        <Hero title={title} image={featured_media} />
        <Container>
          <Content>
            <TextColumn>{parsedContent}</TextColumn>
            <SharingColumn>
              <SocialMediaSharing title={title} path={path} />
            </SharingColumn>
            <SideColumn>
              {subscribe_image && (
                <OpenIssue src={subscribe_image.source_url} loading="lazy" />
              )}
              <Promo>{subscribe_text}</Promo>
            </SideColumn>
          </Content>
        </Container>
      </Wrapper>
      <Ad type="page/before_footer" />
    </>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`

const Content = styled(ArticleContent)`
  margin-bottom: 110px;
`

const OpenIssue = styled.img`
  display: block;
  margin-top: -5px;
  margin-bottom: 50px;

  ${media.tablet} {
    max-width: none;
    margin-bottom: 20px;
  }
`

const Promo = styled(PrimaryTitle)`
  ${media.tablet} {
    letter-spacing: -0.001em;
  }
`
