import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import parseContent from "src/utils/parseContent"
import SEO from "src/components/SEO"
import { PageWrapper } from "src/layouts/BaseLayout"
import Ad from "src/components/Ad"
import Header from "src/components/Header"
import Hero from "src/components/Hero"
import Container from "src/components/Container"
import SocialMediaSharing from "src/components/SocialMediaSharing"
import {
  ArticleContent,
  TextColumn,
  SharingColumn,
  SideColumn,
} from "src/templates/article"
import { pageNormalizer } from "src/utils/contentNormalizers"

export const query = graphql`
  fragment PageFragment on wordpress__PAGE {
    slug
    title
    content
    path
    date(formatString: "DD MMMM YYYY")
    featured_media {
      ...MediaFragment
    }
  }

  query pageByIdQuery($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...PageFragment
    }
  }
`

export default props => {
  const { wordpressPage, site } = props.data
  const { title, content, path, featured_media } = pageNormalizer(wordpressPage)

  return (
    <>
      <SEO title={title} image={featured_media} />
      <Ad type="page/before_header" />
      <PageWrapper>
        <Header />
        <Hero title={title} image={featured_media} />
        <Container>
          <Content>
            <TextColumn>{parseContent(content)}</TextColumn>
            <SharingColumn>
              <SocialMediaSharing title={title} path={path} />
            </SharingColumn>
            <SideColumn>
              <Ad type="page/sidebar" />
            </SideColumn>
          </Content>
        </Container>
      </PageWrapper>
      <Ad type="page/before_footer" />
    </>
  )
}

const Content = styled(ArticleContent)`
  margin-bottom: 60px;

  ${media.tablet} {
    margin-bottom: 110px;
  }
`
